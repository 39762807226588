<template>
    <div :id="(index % 2 === 0) ? 'results_even' : 'results_odd'" class="search-results-items vue-result-items">
        <Loading :active="getDocRowActionStatus('bulkActionProcessing')(index)"
                 :is-full-page="false" loader="dots" color='#202020' :width="40" :height="40"
                 :can-cancel="false"
                 backgroundColor="#ffffff"
                 :opacity="0.8"
                 :z-index="999"
        />
        <SaiCheckbox :options="[{val: '', key: record.doc_id}]"
                     class="search-result-item"
                     name="search-result-item"
                     :value="getBulkSelectedRecords"
                     @change="handleCheckboxChange"
        />
        <div class="search-results-items-document">
            <h2><a
                v-bind:href="'/management/display/index/' + docOffset(index) + '/' + record.doc_id + '/-/' + getSearchResultsField('searchContext')"  @click="handleProductLinkClick(record.name, record.doc_id)"
                class="linkname">{{ record.name }}</a><span v-html="getIcons(record)"></span></h2>
            <h3 v-if="record.title"><span class="search-docTitle">{{ $t('frm_doctitle') }}</span>: <span
                v-html="record.title"></span></h3>
            <p v-if="record.subtitle"><span class="search-subtitle-title">{{ $t('frm_subTitle') }}</span>:
                <span v-html="record.subtitle"></span></p>
            <p v-if="record.languages"><span class="search-language-title">{{ $t('filter_language') }}</span>:
                {{ record.languages }}</p>
            <p v-if="record.historic_versions.length && historicVersionsList(record) !=''">
                <span class="search-historic-versions-title">{{ $t('frm_historicVersions') }}</span>:
                <span v-html="historicVersionsList(record)"></span>
            </p>
        </div>
        <div class="search-results-items-status">
            <div :class="getDocStatus(record).class" :title="$t(getDocStatus(record).title)">
                <i class="si si-check-circle" v-if="['CUR', 'current'].indexOf(getDocStatus(record).status) > -1 "></i>
                <span v-html="$t(getDocStatus(record).title)"></span>
            </div>
        </div>
        <div v-if="record.docVersionId == 0" class="search-results-items-version">{{ record.version.version }}</div>
        <div v-else class="search-results-items-version"><a
            v-bind:href="'/management/display/version/' + record.docVersionId + '/' + record.doc_id + '/-/' + getSearchResultsField('searchContext')"
            class="linkname" v-html="record.version.version"></a>
        </div>
        <div class="search-results-items-action">
            <SearchResultRowActions :record="record" :row-index="index"/>
        </div>
        <div style="clear: both;"></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import {createNamespacedHelpers} from 'vuex';
import SaiCheckbox from "@/components/common/SaiCheckbox";
import i18n from "@/plugins/i18n";

import SearchResultRowActions from './SearchResultRowActions';

const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
const {mapGetters: searchBarGetters} = createNamespacedHelpers('searchbar');

export default {
    name: 'SearchResultsRecord',
    components: {
        SaiCheckbox,
        SearchResultRowActions,
        Loading
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        record: {
            type: Object, default: () => {
            }
        },
        index: {type: Number}
    },
    computed: {
        ...mapGetters([
            'getSearchResultsField',
            'getBulkSelectedRecords',
            'getDocRowActionStatus'
        ]),
        ...searchBarGetters([
            'isPowerUser',
        ])
    },
    methods: {
        ...mapActions([
            'setBulkSelectedRecords'
        ]),
        handleCheckboxChange: function ($event) {
            this.setBulkSelectedRecords($event);
        },
        getDocStatus(document) {
            const status = {
                'cancelled': {status: "cancelled", class: "sai-product-status-cancelled", title: "doc_status_c"},
                'superceded': {status: "superceded", class: "sai-product-status-superceded", title: "doc_status_s"},
                'partiallySuperseded': {
                    status: "partiallySuperseded",
                    class: "sai-product-status-partiallySuperseded",
                    title: "doc_status_p"
                },
                'current': {status: "current", class: "sai-product-status-current", title: "doc_status_cur"},
                'CUR': {status: "CUR", class: "sai-product-status-current", title: "doc_status_cur"},
                'C': {status: "C", class: "sai-product-status-cancelled", title: "doc_status_c"},
                'T': {status: "T", class: "sai-product-status-cancelled", title: "doc_status_c"},
                'S': {status: "S", class: "sai-product-status-superceded", title: "doc_status_s"},
                'AVS': {status: "AVS", class: "sai-product-status-historical", title: "doc_status_avs"},
                'P': {status: "P", class: "sai-product-status-partiallySuperseded", title: "doc_status_p"},
                'O': {status: "O", class: "sai-product-status-historical", title: "doc_status_o"},
                'RE': {status: "RE", class: "sai-product-status-historical", title: "doc_status_re"},
                'NA': {status: "NA", class: "sai-product-status-historical", title: "doc_status_na"},
                'W': {status: "W", class: "sai-product-status-withdrawn", title: "doc_status_w"},
                'AP': {status: "AP", class: "sai-product-status-historical", title: "doc_status_ap"},
            };
            let docStatusCode = 'current';
            if (document.docStatusCode) { //only doc based on new schema has status is not empty
                docStatusCode = document.docStatusCode;
            } else if (document.cancelled && document.cancelled == 'Y') {
                docStatusCode = 'cancelled';
            }
            else if (document.superceded && document.superceded == 'Y') {
                docStatusCode = 'superceded';
            }
            else if (document.superceded && document.superceded == 'P') {
                docStatusCode = 'partiallySuperseded';
            }


            return status[docStatusCode];
        },
        getIcons(record) {
            let output = '';
            const myDoc = (record.myDoc && record.myDoc === 'Y');
            const inColl = (record.collection && record.collection === 'Y');
            const created = (record.recently_created && record.recently_created === 'Y');
            const modified = (record.recently_modifed && record.recently_modifed === 'Y');
            const notes = (record.notes && record.notes === 'Y');
            const watch = (record.watch && parseInt(record.watch) > 0);
            const docs = (record.docs && record.docs === 'Y');
            const links = (record.links && record.links === 'Y');
            const unpublished = (record.unpublished && record.unpublished === 'Y');
            const lawlex = (record.lawlex && record.lawlex === 'Y');

            if (myDoc) {
                output += "<div class='class'></div>";
            }
            if (lawlex) {
                output += "<i class='icon si si-legislation' title='" + i18n.t('msg_lawlex') + "'></i>";
            }
            if (inColl && this.isPowerUser) {
                output += "<i class='icon si si-folder' title='" + i18n.t('msg_in_collection', [this.getSearchResultsField('activeCollectionName')]) + "'></i>";
            }
            if (watch) {
                // Do nothing.
            } else if (created || modified) {
                output += "<i class='icon si si-info' title='" + (modified ? i18n.t('msg_modified') : i18n.t('msg_created')) + "'></i>";
            }
            if (docs) {
                output += "<i class='icon si si-document-green' title='" + i18n.t('msg_docs_avail') + "' style='font-size: 1.2rem;margin-right: 6px;'></i>";
            }
            if (links) {
                output += "<i class='icon si si-link-intranet' title='" + i18n.t('msg_links_avail') + "' style='font-size: 1.2rem;margin-right: 6px;'></i>";
            }
            if (notes) {
                output += "<i class='icon icon-edit' title='" + i18n.t('msg_notes_avail') + "'></i>";
            }
            if (unpublished) {
                output += "<i class='icon icon-remove-sign' title='" + i18n.t('msg_unpublished') + "'></i>";
            }
            return output;
        },
        historicVersionsList(record) {
            let output = '';
            let counter = 0;
            if (record.historic_versions.length === 0) {
                return output;
            }
            record.historic_versions.some(function (element) {
                if (record.docVersionId !== element.version_id) {
                    counter++;
                    if (counter > 9) {
                        output += "...";
                        return true;
                    }
                    if (output.length) {
                        output += '&nbsp;';
                    }
                    output += "<a href='/management/display/version/" + element.version_id + "/" + record.doc_id + "' class='linkversion'>" + element.version + "</a>"
                }
            })
            return output;
        },
        docOffset(rowIndex) {
            const currentPage = this.getSearchResultsField('page');
            const recordsPerPage = this.getSearchResultsField('recordsPerPage');
            const pageOffset = (currentPage - 1) * recordsPerPage;
            return pageOffset + rowIndex;
        },
        handleProductLinkClick(docNo, docId) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'event' : 'SearchEvent', 'eventCategory': 'Search', 'eventAction':  'StandardSearchResultDocClick', 'eventLabel': 'Standard Result Doc Click', 'docNo': docNo, docTitle: 'UNKNOWN', 'docId': docId, 'resultCount': 'UNKNOWN'});
        }
    }
}
</script>

<style lang="scss">
.search-results-items {
    &.vue-result-items {
        position: relative;
    }
}
</style>